import { useState } from 'react';
import { Button, Collapse, Form, Input } from 'antd';

import { validateVtexCredentials } from '../../../../domain/catalog';
import sendNotification from '../../../../components/Notification/index.tsx';
import CredentialTag from './CredentialTag';

const { Panel } = Collapse;

const VtexCredentials = () => {
  const [isValidVtexCredentials, setIsValidVtexCredentials] = useState('');
  const [loading, setLoading] = useState(false);

  const handleVtexCredentials = async formValues => {
    const { appKey, appToken } = formValues;

    if (isValidVtexCredentials) setIsValidVtexCredentials('');

    setLoading(true);
    const result = await validateVtexCredentials({ appKey, appToken });
    setLoading(false);

    if (result instanceof Error) {
      return sendNotification(
        'Ocorreu algum problema nesta operação.',
        'error',
      );
    }

    const isUnauthorized = Object.values(result?.data).find(
      value => value?.message?.status === '403',
    );
    const isInvalid = Object.values(result?.data).find(
      value => value?.message?.status === '401',
    );

    if (isUnauthorized) {
      return setIsValidVtexCredentials('unauthorized');
    }
    if (isInvalid) {
      return setIsValidVtexCredentials('invalid');
    }
    return setIsValidVtexCredentials('valid');
  };

  return (
    <Collapse style={{ width: '90%' }}>
      <Panel header="Validar credenciais" key="vtex-credentials">
        <Form onFinish={handleVtexCredentials}>
          <Form.Item
            label="VTEX appKey"
            name="appKey"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Este campo não pode ficar em branco',
              },
              () => ({
                validator(_, value) {
                  if (!value || /^vtexappkey-/.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Uma appKey deve iniciar por "vtexappkey-"'),
                  );
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="VTEX appToken"
            name="appToken"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Este campo não pode ficar em branco',
              },
            ]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Verificar
            </Button>
          </div>
        </Form>

        <CredentialTag vtexCredentialStatus={isValidVtexCredentials} />
      </Panel>
    </Collapse>
  );
};

export default VtexCredentials;
