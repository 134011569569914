import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Button, Card, Collapse, Form, Input, Space, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import sendNotification from '../../../../components/Notification/index.tsx';
import api from '../../../../infrascruture/httpRequest';

const { Panel } = Collapse;
const { Paragraph, Text } = Typography;

const SkuLogs = ({ baseUrl, formatDate }) => {
  const [skuLogs, setSkuLogs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const getSkuLogs = async ({ sku }) => {
    try {
      setIsLoading(true);
      setSkuLogs({});

      const url = `${baseUrl}/config/${id}/log/notification/${sku}`;
      const response = await api.get(url);

      switch (response.status) {
        case 200:
          setSkuLogs(response.data);
          break;

        case 404:
          sendNotification('SKU não encontrado', 'error');
          break;

        default:
          sendNotification('Algo deu errado, tente novamente', 'error');
          break;
      }
    } catch (error) {
      setSkuLogs({});
      sendNotification('Algo deu errado, tente novamente', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const getSkuLogsInfo = () => {
    try {
      const {
        sku,
        productId,
        updatedAt,
        dateModified,
        isActive,
        stockModified,
        priceModified,
        status,
      } = skuLogs;

      return (
        <Card>
          <Space direction="vertical">
            <Text>SKU: {sku}</Text>
            <Text>ID do produto: {productId}</Text>
            <Text>Ativo: {isActive === 'true' ? 'Sim' : 'Não'}</Text>
            <Text>Data de alteração na VTEX: {formatDate(dateModified)}</Text>
            <Text>Data de execução da integração: {formatDate(updatedAt)}</Text>
            <Text>
              Estoque foi modificado: {stockModified === 'true' ? 'Sim' : 'Não'}
            </Text>
            <Text>
              Preço do produto foi modificado:{' '}
              {priceModified === 'true' ? 'Sim' : 'Não'}
            </Text>
            <Text>
              Status: {status === 'pending' ? 'Pendente' : 'Finalizado'}
            </Text>
          </Space>
        </Card>
      );
    } catch (e) {
      sendNotification('Algo deu errado, tente novamente', 'error');
      console.error(e);
      return '';
    }
  };

  return (
    <Collapse>
      <Panel header="Registro de notificação de um SKU" key="sku_logs">
        <Paragraph>
          <InfoCircleTwoTone /> Visualização dos logs de um SKU
        </Paragraph>

        <Form onFinish={getSkuLogs} style={{ display: 'flex' }}>
          <Form.Item
            label="SKU"
            name="sku"
            rules={[
              {
                required: true,
                message: 'Defina o SKU que deseja procurar',
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: '20px' }}
            loading={isLoading}
          >
            Buscar
          </Button>
        </Form>

        {Object.keys(skuLogs).length > 0 && getSkuLogsInfo()}
      </Panel>
    </Collapse>
  );
};

SkuLogs.propTypes = {
  baseUrl: PropTypes.string,
  formatDate: PropTypes.func,
};

export default SkuLogs;
