import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Button, Card, Collapse, Table, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import sendNotification from '../../../../components/Notification/index.tsx';
import api from '../../../../infrascruture/httpRequest';

const { Panel } = Collapse;
const { Paragraph, Text } = Typography;

const PENDING = 'pending';
const FINISHED = 'finished';

const NotificationLogs = ({ baseUrl, formatDate }) => {
  const [logsList, setLogsList] = useState([]);
  const [currentFilter, setCurrentFilter] = useState('');
  const [isPendingLoading, setIsPendingLoading] = useState(false);
  const [isFinishedLoading, setIsFinishedLoading] = useState(false);
  const { id } = useParams();

  const getNotificationLogs = async filter => {
    try {
      if (filter === PENDING) setIsPendingLoading(true);
      else setIsFinishedLoading(true);

      setCurrentFilter(filter);
      setLogsList([]);

      const url = `${baseUrl}/config/${id}/log/notification?filter=${filter}`;
      const response = await api.get(url);

      switch (response.status) {
        case 200:
          if (
            response?.data === null ||
            Object.keys(response.data).length === 0
          ) {
            sendNotification('Nenhuma atualização encontrada', 'error');
            return;
          }

          setLogsList(response.data);
          break;

        case 404:
          sendNotification('SKU não encontrado', 'error');
          break;

        default:
          sendNotification('Algo deu errado, tente novamente', 'error');
          break;
      }
    } catch (error) {
      sendNotification('Algo deu errado, tente novamente', 'error');
    } finally {
      setIsPendingLoading(false);
      setIsFinishedLoading(false);
    }
  };

  const generateLogsTable = () => {
    const columns = [
      {
        title: 'SKU',
        dataIndex: 'sku',
        key: 'sku',
        sorter: (a, b) => a.sku - b.sku,
      },
      {
        title: 'Id do produto',
        dataIndex: 'id',
        key: 'id',
        sorter: (a, b) => a.sku - b.sku,
      },
      {
        title: 'Modificado na VTEX',
        dataIndex: 'date',
        key: 'date',
        sorter: (a, b) => new Date(a.rawDate) - new Date(b.rawDate),
      },
      {
        title: 'Processado no Beon',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        sorter: (a, b) => new Date(a.rawUpdatedAt) - new Date(b.rawUpdatedAt),
      },
    ];

    const dataSource = logsList.map(
      ({ dateModified, productId, sku, updatedAt }) => ({
        key: sku,
        date: formatDate(dateModified),
        rawDate: dateModified,
        id: productId,
        sku,
        updatedAt: formatDate(updatedAt),
        rawUpdatedAt: updatedAt,
      }),
    );

    return (
      <Card style={{ marginTop: '20px' }}>
        <Paragraph>
          Total de itens{' '}
          {currentFilter === PENDING ? 'pendentes' : 'finalizados'}:{' '}
          <Text>{logsList.length}</Text>
        </Paragraph>

        <Table
          dataSource={dataSource}
          columns={columns}
          bordered
          pagination={{
            position: ['bottomCenter'],
          }}
        />
      </Card>
    );
  };

  return (
    <Collapse>
      <Panel header="Atualizações recentes" key="processing_logs">
        <Paragraph>
          <InfoCircleTwoTone /> Logs das atualizações recentes recebidas pela
          VTEX.
        </Paragraph>

        <Button
          type="primary"
          htmlType="button"
          onClick={() => getNotificationLogs(PENDING)}
          disabled={isFinishedLoading}
          loading={isPendingLoading}
          style={{ marginRight: '10px' }}
        >
          Pendentes
        </Button>

        <Button
          type="primary"
          htmlType="button"
          onClick={() => getNotificationLogs(FINISHED)}
          disabled={isPendingLoading}
          loading={isFinishedLoading}
        >
          Finalizadas
        </Button>

        {logsList.length > 0 && generateLogsTable()}
      </Panel>
    </Collapse>
  );
};

NotificationLogs.propTypes = {
  baseUrl: PropTypes.string,
  formatDate: PropTypes.func,
};

export default NotificationLogs;
