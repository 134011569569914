import api, { catalogAPI } from '../../infrascruture/httpRequest';
import getEndpoint from '../../utils/getEndpoint';

const APP_MODE = process.env.REACT_APP_MODE;

const endpointCatalogIntegra = getEndpoint('apiCatalogIntegra');
const endpointIntegraMultiplatformSvc = getEndpoint('svcIntegraMultiplatform');
const endpointIntegraFeed = getEndpoint('svcIntegraFeed');
const endpointLogs = getEndpoint('apiLogs');

export const getDataFromEndpoints = async id => {
  try {
    const integrations = await api.get(
      `${endpointCatalogIntegra}/config/get/${id}/`,
    );
    return integrations;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const getTenantIntegrationsGeneralInfo = async (id, provider) => {
  try {
    let url;

    if (provider === 'integra-feed') {
      url = `${endpointIntegraFeed}/config/${id}`;
    } else {
      url = `${endpointIntegraMultiplatformSvc}/${id}/config/${provider}`;
    }

    const integrations = await api.get(url);

    return integrations;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const getIntegrationGeneral = async () => {
  try {
    const result = await api.get(`${endpointCatalogIntegra}/config/describe/`);

    if (result.status === 200) {
      return result;
    }
  } catch (e) {
    console.error(e);
    return {};
  }
  return {};
};

export const getIntegrationInterface = async type => {
  try {
    const result = await api.get(
      `${endpointCatalogIntegra}/config/describe/platform?type=${type}`,
    );

    if (result.status === 200) {
      return result;
    }
  } catch (e) {
    console.error(e);
    return {};
  }
  return {};
};

export const getIntegrationConfig = async ({ tenantId, integrationId }) => {
  try {
    const result = await api.get(
      `${endpointCatalogIntegra}/config/get/${tenantId}/integration/${integrationId}`,
    );

    if (result.status === 200) {
      console.log('config', result);
      return result;
    }
  } catch (e) {
    console.error(e);
    return [];
  }
  return [];
};

export const getIntegrationData = async (tenantId, integrationId) => {
  const url = `${endpointCatalogIntegra}/config/get/${tenantId}/integration/${integrationId}`;

  try {
    console.log('Iniciando busca integração: ', url);
    const result = await api.get(url);
    console.log('Busca terminada:', result);
    return result;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const createConfig = async (values, tenantId, provider) => {
  try {
    const config = {
      ...values,
    };

    let result;
    if (provider === 'feed' || provider === 'integra-feed') {
      result = await api.post(`${endpointIntegraFeed}/config`, config);
    } else {
      result = await api.post(
        `${endpointIntegraMultiplatformSvc}/${tenantId}/config/${provider}`,
        config,
      );
    }

    if (result.status === 201) {
      return true;
    }
  } catch (err) {
    console.log('status final: ', err);
    return err;
  }
  return false;
};

export const updateConfig = async ({
  formattedValues,
  tenantId,
  integrationId,
  provider,
}) => {
  try {
    delete formattedValues.provider;
    const parameters = { ...formattedValues };
    let result;

    if (provider === 'feed' || provider === 'integra-feed') {
      result = await api.put(
        `${endpointIntegraFeed}/config/${integrationId}`,
        parameters,
      );
    } else {
      result = await api.post(
        `${endpointIntegraMultiplatformSvc}/${tenantId}/config/${provider}`,
        parameters,
      );
    }

    if (result.status === 204) {
      return true;
    }
  } catch (err) {
    console.log('status final: ', err);
    return err;
  }
  return false;
};

export const removeConfig = async ({ integrationId, tenantId }) => {
  try {
    const result = await api.post(
      `${endpointCatalogIntegra}/config/remove/${tenantId}/integration/${integrationId}`,
    );
    if (result.status === 204) {
      return true;
    }
  } catch (err) {
    console.error(err);
    return err;
  }
  return false;
};

export const checkFeedXML = async feedUrl => {
  try {
    const result = await api.post(`${endpointIntegraFeed}/feedxml`, feedUrl);

    if (result.status === 200) {
      console.log(result);
      return result;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
  return false;
};

export const searchFeedXML = async ({ feedUrl, term }) => {
  try {
    const result = await api.get(
      `${endpointIntegraFeed}/search?term=${term}&feed_url=${feedUrl}`,
    );

    if (result.status === 200) {
      console.log(result);
      return result;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
  return false;
};

export const validateVtexCredentials = async ({ appKey, appToken }) => {
  try {
    const domain = appKey.substring(
      appKey.indexOf('-') + 1,
      appKey.lastIndexOf('-'),
    );

    const credentials = {
      domain,
      appKey,
      appToken,
    };

    const result = await api.post(
      `${endpointIntegraMultiplatformSvc}`,
      credentials,
    );
    return result;
  } catch (e) {
    return e;
  }
};

export const getIntegrationFormKeys = async platform => {
  let url;

  if (platform === 'feed' || platform === 'integra-feed') {
    url = `https://integra-feed.${APP_MODE}.usebeon.io/describe`;
  } else {
    url = `https://integra-multiplatform-api.${APP_MODE}.usebeon.io/describe/${platform}`;
  }

  try {
    const result = await api.get(url);
    return result;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const getProductFromCatalog = async (tenantId, type, term) => {
  const encodedTerm = encodeURIComponent(term);
  const url = `${tenantId}/product/${type}/${encodedTerm}`;

  try {
    const result = await catalogAPI.get(url);
    return result;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const getFeedTenantLogs = async tenantId => {
  const url = `${endpointIntegraFeed}/logs/${tenantId}`;

  try {
    const result = await api.get(url);

    return result;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getFeedLogDetails = async (tenantId, logId) => {
  const url = `${endpointIntegraFeed}/logs/${tenantId}/${logId}`;

  try {
    const result = await api.get(url);

    return result;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getFeedIntegrationPreview = async integrationId => {
  const url = `${endpointIntegraFeed}/feed/preview/${integrationId}`;

  try {
    const result = await api.get(url);

    return result;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const runFeedIntegration = async integrationId => {
  try {
    const url = `${endpointIntegraFeed}/feed/${integrationId}`;

    const result = await api.get(url);

    return result;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const runMultiplatformFullIntegration = async ({
  tenantId,
  platform,
}) => {
  const url = `${endpointIntegraMultiplatformSvc}/${tenantId}/integra/${platform}`;

  try {
    const result = await api.get(url);
    return result;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const runMultiplatformUnitaryIntegration = async ({
  tenantId,
  sku,
  platform,
}) => {
  const url = `${endpointIntegraMultiplatformSvc}/${tenantId}/integra/${sku}/${platform}`;

  try {
    const result = await api.get(url);

    return result;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getMultiplatformIntegrationRunningStatus = async tenantId => {
  const url = `${endpointIntegraMultiplatformSvc}/${tenantId}/logs`;

  try {
    const result = api.get(url);

    return result;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getAllLogs = async tenantId => {
  const url = `${endpointLogs}/logs/${tenantId}`;

  try {
    const result = await api.get(url);

    return result;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getPageLogs = async (tenantId, from, to) => {
  const url = `${endpointLogs}/logs/${tenantId}?from=${from}&to=${to}`;

  try {
    const result = await api.get(url);

    return result;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const generateLog = async log => {
  const url = `${endpointLogs}/logs`;

  try {
    const result = await api.post(url, log);

    return result;
  } catch (e) {
    console.log(e);
    return e;
  }
};
