import React from 'react';
import { Space } from 'antd';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import SkuLogs from './Panels/SkuLogs';
import NotificationLogs from './Panels/NotificationLogs';
import IntegrationLogs from './Panels/IntegrationLogs';
import getEndpoint from '../../../utils/getEndpoint';

const VtexLogs = () => {
  const baseUrl = getEndpoint('svcVtex');

  const formatDate = date => {
    const dateFormat = 'dd/MMM/yyyy HH:mm:ss';
    return format(new Date(date), dateFormat, { locale: ptBR });
  };

  return (
    <Space direction="vertical" style={{ width: '90%' }}>
      <SkuLogs baseUrl={baseUrl} formatDate={formatDate} />
      <NotificationLogs baseUrl={baseUrl} formatDate={formatDate} />
      <IntegrationLogs baseUrl={baseUrl} formatDate={formatDate} />
    </Space>
  );
};

export default VtexLogs;
