import {
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Button, Collapse, Form, Input, Space, Tooltip } from 'antd';
import { useCatalog } from '../../../../context/catalog';

const { Panel } = Collapse;

const SpecsParseRules = ({ formKey }) => {
  const { integrationData } = useCatalog();
  const savedSpecsPS = integrationData[formKey[0]] || {};

  const { helper, label } = formKey[1];

  const showCurrentAttrs = () => {
    const normalizedAttrs = savedSpecsPS
      ? Object.entries(savedSpecsPS).map(([key, value]) => {
          return {
            spec_name: key,
            spec_rule: value,
          };
        })
      : [];

    return normalizedAttrs;
  };

  return (
    <Collapse
      defaultActiveKey={`${Object.entries(savedSpecsPS)?.length ? ['1'] : []}`}
      style={{ marginBottom: '2rem' }}
    >
      <Panel
        header={
          <>
            {label}{' '}
            <Tooltip title={helper}>
              <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          </>
        }
        key="1"
      >
        <Form.Item>
          <Form.List name={formKey[0]} initialValue={showCurrentAttrs()}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: 'flex',
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'spec_name']}
                      rules={[
                        {
                          required: true,
                          message: 'Inclua o nome da especificação',
                        },
                      ]}
                    >
                      <Input placeholder="Nome" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      initialValue="linebreak"
                      name={[name, 'spec_rule']}
                      rules={[
                        {
                          required: true,
                          message: 'Inclua a regra da especificação',
                        },
                      ]}
                    >
                      <Input placeholder="Regra" value="linebreak" />
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Adicionar atributo
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      </Panel>
    </Collapse>
  );
};

export default SpecsParseRules;
